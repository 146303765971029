import React, {useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import {Menu, MenuItem, ProSidebar, SidebarContent, SidebarFooter, SubMenu} from 'react-pro-sidebar';
import {useDispatch, useSelector} from 'react-redux';
import CustomScroll from 'react-custom-scroll';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import {toggleRightSidebar} from '../../store/layout/actions';
import CollapseIcon from '../../images/sidebar-icon-colapse.svg';

const SubmenuIcon = ({iconClassName}) => (
  <i className={iconClassName} />
);

const Sidebar = ({
  toggleModal,
  trial,
  isTrialClosed,
  openOnboardingModal,
}) => {
  const { showRightSidebar: collapsed } = useSelector((state) => state.Layout);
  const { isWebTestEnabled, companyDetails: { bluetooth_enabled: isBluetoothEnabled } } = useSelector((state) => state.Company);
  const { data: profileData } = useSelector((state) => state.Profile);
  const dispatch = useDispatch();
  const location = useLocation();
  const [openMenu, setOpenMenu] = useState('overview');
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [subMenus, setSubMenus] = useState({
    overview: ['/admin/digital-experience', '/admin/broadband-insights'].includes(location.pathname),
    users: ['/admin/users', '/admin/invited-users'].includes(location.pathname),
    network: ['/admin/tests'].includes(location.pathname),
    settings: [
      '/admin/applications-settings',
      '/admin/announcements',
      '/admin/subscriptions',
      '/admin/sdk-credentials',
      '/admin/dashboard-settings',
      '/admin/extension-settings',
      '/admin/browser-test-settings',
      '/admin/bluetooth-settings',
    ].includes(location.pathname),
    help: false,
    logs: ['/admin/logs'].includes(location.pathname),
    issues: ['/admin/issues'].includes(location.pathname),
  });
  useEffect(() => {
    const cameFrom = window.Cookies.get('from');
    if (cameFrom) {
      window.Cookies.remove('from');
    }

    if (location.search?.includes('?invite-users')) {
      toggleModal('invite');
    }
    if (location.search?.includes('?invite-admin')) {
      toggleModal('admin');
    }
    if (location.search?.includes('?request-test')) {
      toggleModal('test');
    }
  }, []);
 
  const toggleSubmenu = (submenuName, status) => {
    setOpenMenu(status ? submenuName : null);
    setMenuIsOpen(status);
    setSubMenus(prevSubMenus => ({
      ...prevSubMenus,
      [submenuName]: status,
    }));
  };
  
  return (
    <div
      className={
        `sidebar${collapsed ? ' active' : ''}${trial && !isTrialClosed ? ' sidebar_top' : ''}`
      }
    >
      <button
        className="btn btn-link sidebar-btn"
        type="button"
        onClick={() => dispatch(toggleRightSidebar(!collapsed))}
      >
        <img src={CollapseIcon} alt="collapse-icon" width="20px" height="20px" />
      </button>
      <ProSidebar collapsed={!collapsed}>
        <SidebarContent>
          <CustomScroll heightRelativeToParent="100%">
            <Menu>
              <SubMenu
                title="Overview"
                icon={<SubmenuIcon iconClassName="mdi mdi-home-outline" />}
                open={subMenus.overview}
                onOpenChange={(open) => toggleSubmenu('overview', open)}
              >
                <MenuItem active>
                  <NavLink to="/admin/broadband-insights" activeClassName="active">Broadband Insights</NavLink>
                </MenuItem>
              </SubMenu>

              <SubMenu
                title="Network"
                icon={<SubmenuIcon iconClassName="mdi mdi-lan" />}
                open={subMenus.network}
                onOpenChange={(open) => toggleSubmenu('network', open)}
              >
                <MenuItem>
                  <NavLink to="/admin/tests" activeClassName="active">Test Results</NavLink>
                </MenuItem>
              </SubMenu>

              <SubMenu
                title="Users"
                icon={<SubmenuIcon iconClassName="mdi mdi-account-multiple-outline" />}
                open={subMenus.users}
                onOpenChange={(open) => toggleSubmenu('users', open)}
              >
                <MenuItem>
                  <NavLink to="/admin/users" activeClassName="active">Users List</NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink to="/admin/invited-users" activeClassName="active">Invited Users</NavLink>
                </MenuItem>
              </SubMenu>

              <MenuItem
                icon={<SubmenuIcon iconClassName="mdi mdi-alert-circle-outline" />}
                onClick={(open) => toggleSubmenu('issues', open)}
                active={subMenus.issues}
              >
                <NavLink to="/admin/issues" activeClassName="active">Issues</NavLink>
              </MenuItem>

              <MenuItem
                icon={<SubmenuIcon iconClassName="mdi mdi-clipboard-outline" />}
                onClick={(open) => toggleSubmenu('logs', open)}
                active={subMenus.logs}
              >
                <NavLink to="/admin/logs" activeClassName="active">Logs</NavLink>
              </MenuItem>

              <SubMenu
                title="Settings"
                icon={<SubmenuIcon iconClassName="mdi mdi-settings-outline" />}
                open={subMenus.settings}
                onOpenChange={(open) => toggleSubmenu('settings', open)}
              >
                <MenuItem>
                  <NavLink to="/admin/applications-settings" activeClassName="active">App Settings</NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink to="/admin/announcements" activeClassName="active">Announcements</NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink to="/admin/subscriptions" activeClassName="active">Subscriptions</NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink to="/admin/sdk-credentials" activeClassName="active">SDK Credentials</NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink to="/admin/dashboard-settings" activeClassName="active">Dashboard Settings</NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink to="/admin/extension-settings" activeClassName="active">Extension Settings</NavLink>
                </MenuItem>
                {isBluetoothEnabled && (
                  <MenuItem>
                    <NavLink to="/admin/bluetooth-settings" activeClassName="active">Bluetooth SDK</NavLink>
                  </MenuItem>
                  )}
                {(isWebTestEnabled && (
                  profileData?.role?.toLowerCase() === 'super admin' || profileData?.role?.toLowerCase() === 'hubble admin'
                )) && (
                  <MenuItem active>
                    <NavLink to="/admin/browser-test-settings" activeClassName="active">Browser Test</NavLink>
                  </MenuItem>
                )}
              </SubMenu>

              <SubMenu
                title="Help"
                icon={<SubmenuIcon iconClassName="mdi mdi-help-circle-outline" />}
                open={subMenus.help}
                onOpenChange={(open) => toggleSubmenu('help', open)}
              >
                <MenuItem active>
                  <button className="btn btn-link" type="button" onClick={openOnboardingModal}>Setup Guide</button>
                </MenuItem>
                <MenuItem>
                  <a href="mailto:contact@hubbleiq.com">Contact Support</a>
                </MenuItem>
              </SubMenu>
            </Menu>
          </CustomScroll>
        </SidebarContent>
        <SidebarFooter>
          <div className="sidebar-footer-wrapper">
            <button
              className="btn btn-link"
              type="button"
              onClick={() => dispatch(toggleRightSidebar(!collapsed))}
            >
              <span>
                <i className="mdi mdi-menu-open" />
                <i className="mdi mdi-menu" />
              </span>
            </button>
          </div>
        </SidebarFooter>

      </ProSidebar>
    </div>
  );
};

export default Sidebar;
